@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (chartData() != null) {
  <kendo-chart>
    <kendo-chart-series>
      <kendo-chart-series-item
        [autoFit]="true"
        type="funnel"
        [data]="chartData()"
        field="count"
        categoryField="stat"
        [dynamicHeight]="false"
      >
        <kendo-chart-series-item-labels background="none"> </kendo-chart-series-item-labels>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-tooltip>
      <ng-template
        kendoChartSeriesTooltipTemplate
        let-category="category"
      >
        {{ category }}
      </ng-template>
    </kendo-chart-tooltip>
    <kendo-chart-legend
      position="bottom"
      [visible]="true"
    ></kendo-chart-legend>
  </kendo-chart>

  <kendo-dialog
    *ngIf="showPopup"
    (close)="close()"
  >
    <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
    <kendo-grid
      class="mxh-80v"
      [filterable]="true"
      [data]="gridService | async"
      [pageable]="{
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [25, 50, 100, 200]
      }"
      [pageSize]="gridService.state.take"
      [skip]="gridService.state.skip"
      [(filter)]="gridService.state.filter"
      [loading]="gridService.loading"
      (dataStateChange)="gridService.onStateChange($event)"
      [sort]="this.gridService.state.sort"
      [sortable]="true"
    >
      <ng-template kendoGridToolbarTemplate>
        <button
          type="button"
          kendoGridExcelCommand
        >
          Export to Excel
        </button>
      </ng-template>
      <kendo-grid-column
        field="alert_received_on"
        title="Alert Timestamp"
        [filterable]="false"
      ></kendo-grid-column>
      <kendo-grid-column
        field="case_id"
        title="SOAR Case #"
      ></kendo-grid-column>
      <kendo-grid-column
        field="case_source"
        title="Alert Source"
      ></kendo-grid-column>
      <kendo-grid-column
        field="event_name"
        title="Event"
      ></kendo-grid-column>
      <kendo-grid-column
        field="ticket_id"
        title="Ticket #"
      ></kendo-grid-column>
      <kendo-grid-column
        field="priority"
        title="Priority"
      ></kendo-grid-column>
      <kendo-grid-column
        field="ticket_status"
        title="Ticket Status"
      ></kendo-grid-column>
      <kendo-grid-column
        field="created_time"
        title="Ticket Creation"
      ></kendo-grid-column>
      <kendo-grid-column
        field="ticket_closed_time"
        title="Ticket Closed"
      ></kendo-grid-column>
      <kendo-grid-excel
        fileName="ticket-breakdown-data.xlsx"
        [fetchData]="allData"
      ></kendo-grid-excel>
    </kendo-grid>
  </kendo-dialog>
} @else {
  <h1>No Data</h1>
}
