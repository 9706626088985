@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (ticketsByAsset().length > 0) {
  <div class="chart-container">
    <kendo-chart-breadcrumb [chart]="instance"></kendo-chart-breadcrumb>

    <kendo-chart
      #instance
      (seriesClick)="onSeriesClick($event)"
      (render)="popupService.attachSurfaceHandlers($event, popupTemplate)"
    >
      <kendo-chart-tooltip> </kendo-chart-tooltip>
      <kendo-chart-series>
        <kendo-chart-series-item
          type="column"
          [data]="ticketsByAsset()"
          field="count"
          categoryField="description"
          drilldownField="description"
        >
          <kendo-chart-series-item
            *kendoChartDrilldownSeries="let make = drilldownValue"
            [data]="ticketsByEventName()"
            field="count"
            categoryField="description"
          >
          </kendo-chart-series-item>
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item>
          <kendo-chart-category-axis-item-labels
            [content]="truncateCategoryLabel"
            rotation="auto"
          ></kendo-chart-category-axis-item-labels>
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
    </kendo-chart>

    <ng-template #popupTemplate>
      <div class="chart-popup k-tooltip">
        {{ popupService.popupContent }}
      </div>
    </ng-template>
    
  <kendo-dialog
  *ngIf="showPopup"
  (close)="close()"
>
  <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
  <kendo-grid
    class="mxh-80v"
    [filterable]="true"
    [data]="gridService | async"
    [pageable]="{
      buttonCount: 5,
      info: true,
      type: 'numeric',
      pageSizes: [25, 50, 100, 200]
    }"
    [pageSize]="gridService.state.take"
    [skip]="gridService.state.skip"
    [(filter)]="gridService.state.filter"
    [loading]="gridService.loading"
    (dataStateChange)="gridService.onStateChange($event)"
    [sort]="this.gridService.state.sort"
    [sortable]="true"
  >
    <ng-template kendoGridToolbarTemplate>
      <button
        type="button"
        kendoGridExcelCommand
      >
        Export to Excel
      </button>
    </ng-template>
    <kendo-grid-column
      [filterable]="false"
      field="alert_received_on"
      title="Received On"
    ></kendo-grid-column>
    <kendo-grid-column
      field="case_id"
      title="Case Id"
    ></kendo-grid-column>    
    <kendo-grid-column
      field="source_ip"
      title="Source IP"
    ></kendo-grid-column>
    <kendo-grid-column
      field="event_name"
      title="Name"
    ></kendo-grid-column>
    <kendo-grid-column
      field="case_severity"
      title="Case Severity"
    ></kendo-grid-column>
    <kendo-grid-column
      field="case_source"
      title="Case Source"
    ></kendo-grid-column>
    <kendo-grid-excel
      fileName="suspected-assets-data.xlsx"
      [fetchData]="allData"
    ></kendo-grid-excel>
  </kendo-grid>
</kendo-dialog>
  </div>
} @else {
  <h1>No Data</h1>
}
