import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, output, signal } from '@angular/core';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { SVGIcon, plusIcon } from '@progress/kendo-svg-icons';
import { BaseComponent } from '../../../../../../../../core/shared/common/base.component';
import { IngestorBaseService, IngestorRecord } from '../../../services/ingestor.service';
import { IngestorIntegration } from '../../../../../../../../core/services/integration.service';

@Component({
  selector: 'app-ingestor-sources',
  standalone: true,
  imports: [CommonModule, GridModule, InputsModule, LabelModule, IconsModule, ButtonModule],
  templateUrl: './ingestor-sources.component.html',
  styleUrl: './ingestor-sources.component.scss',
  providers: [IngestorBaseService],
})
export class IngestorSourcesComponent extends BaseComponent {
  public plusIcon: SVGIcon = plusIcon;
  public gridService = inject(IngestorBaseService);

  public saved = output<void>();
  public integrationData = input<any>();
  public organizationId = input<any>();
  public ingestorIntegration = input<IngestorIntegration>();
  public data = signal<IngestorRecord[]>([]);

  constructor() {
    super();
    this.initEffect();
  }

 
  initEffect() {
    effect((() => {
      console.log(this.organizationId());
      if(this.organizationId) {
        this.gridService.getIngestorSourcesByOrganization(this.organizationId(), this.ingestorIntegration()!.id)
        .subscribe((data) => {
          this.data.set(data.values);
        });
      }
    }));
  }

  addSource(){
    console.log("Add source");
  }
}
