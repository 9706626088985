import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminAppbarComponent } from '../layout/components/admin-appbar/admin-appbar.component';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-roadmap',
  standalone: true,
  imports: [AdminAppbarComponent],
  templateUrl: './roadmap.component.html',
  styleUrl: './roadmap.component.scss',
})
export class RoadmapComponent implements OnInit, OnDestroy {
  private scriptLoaded = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadFrillWidget();
      }
    });
  }

  loadFrillWidget(): void {
    const existingScript = document.getElementById('frill-widget-script');
    if (existingScript) {
      existingScript.remove(); // Remove old script to prevent duplication
    }

    const script = document.createElement('script');
    script.id = 'frill-widget-script';
    script.src = 'https://widget.frill.co/v2/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }

  ngOnDestroy(): void {
    const script = document.getElementById('frill-widget-script');
    if (script) {
      script.remove(); // Clean up the script on component destruction
    }
  }
}
