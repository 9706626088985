@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (destinationData() !== null) {
  <div class="map-container">
    <kendo-map
      [center]="[40, 0]"
      [zoom]="1"
      [zoomable]="false"
    >
      <kendo-map-layers>
        <kendo-map-tile-layer
          [subdomains]="tileSubdomains"
          [urlTemplate]="tileUrl"
        >
        </kendo-map-tile-layer>
        <kendo-map-bubble-layer
          [data]="destinationData()"
          locationField="location"
          valueField="count"
          [style]="bubbleStyle"
        >
          <kendo-map-bubble-layer-tooltip>
            <ng-template
              kendoMapBubbleTooltipTemplate
              let-dataItem="dataItem"
              let-value="value"
            >
              {{ dataItem.region }}: {{ value }}
            </ng-template>
          </kendo-map-bubble-layer-tooltip>
        </kendo-map-bubble-layer>
      </kendo-map-layers>
    </kendo-map>

    <kendo-dialog
  *ngIf="showPopup"
  (close)="close()"
>
  <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
  <kendo-grid
    class="mxh-80v"
    [filterable]="true"
    [data]="gridService | async"
    [pageable]="{
      buttonCount: 5,
      info: true,
      type: 'numeric',
      pageSizes: [25, 50, 100, 200]
    }"
    [pageSize]="gridService.state.take"
    [skip]="gridService.state.skip"
    [(filter)]="gridService.state.filter"
    [loading]="gridService.loading"
    (dataStateChange)="gridService.onStateChange($event)"
    [sort]="this.gridService.state.sort"
    [sortable]="true"
  >
    <ng-template kendoGridToolbarTemplate>
      <button
        type="button"
        kendoGridExcelCommand
      >
        Export to Excel
      </button>
    </ng-template>
    <kendo-grid-column
      [filterable]="false"
      field="alert_received_on"
      title="Received On"
    ></kendo-grid-column>
    <kendo-grid-column
      field="case_id"
      title="Case Id"
    ></kendo-grid-column>    
    <kendo-grid-column
      field="destination_ip_country_code"
      title="Destination Country"
    ></kendo-grid-column>
    <kendo-grid-column
      field="event_name"
      title="Name"
    ></kendo-grid-column>
    <kendo-grid-column
      field="case_severity"
      title="Case Severity"
    ></kendo-grid-column>
    <kendo-grid-column
      field="case_source"
      title="Case Source"
    ></kendo-grid-column>
    <kendo-grid-excel
      fileName="destination-geolocations-data.xlsx"
      [fetchData]="allData"
    ></kendo-grid-excel>
  </kendo-grid>
</kendo-dialog>
  </div>
} @else {
  <h1>No Data</h1>
}
