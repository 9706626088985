import { Component, CreateEffectOptions, Input, Signal, effect, inject, input, signal } from '@angular/core';
import { MapModule, TileUrlTemplateArgs } from '@progress/kendo-angular-map';
import { ExcelModule, GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { ShapeCreatedEvent } from '@progress/kendo-angular-map';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { ShapeOptions } from '@progress/kendo-drawing';
import { MapData } from '../../../../../../../core/services/analytics.service';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { CommonModule } from '@angular/common';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { MenusModule } from '@progress/kendo-angular-menu';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';
import { SlaViolationsService } from '../sla-adherence/services/slaviolations.service';
import { LayoutModule } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-destination-map',
  standalone: true,
  imports: [MapModule, LayoutModule, ChartsModule, LoaderModule, CommonModule, GridModule, DialogsModule, ExcelModule, MenusModule],
  templateUrl: './destination-map.component.html',
  styleUrls: ['./destination-map.component.scss'],
  providers: [SlaViolationsService],
})
export class DestinationMapComponent extends BaseComponent {
  public gridService = inject(SlaViolationsService);
  @Input() data!: Signal<MapData[]>;

  public destinationData = signal<MapData[]>([
    {
      region: '',
      count: 0,
      location: [0, 0],
    },
  ]);

  public organizationId = input<number | null>();
  public dateRange = input<string[] | null>(null);

  public isLoading = signal(true);
  public showPopup = false;
  tileSubdomains = ['a', 'b', 'c'];
  tileUrl = (e: TileUrlTemplateArgs): string => `https://${e.subdomain}.tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;

  bubbleStyle: ShapeOptions = {
    fill: {
      color: 'orange',
      opacity: 0.5,
    },
    stroke: {
      width: 1,
      color: 'black',
    },
  };

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const destinationMapData = this.data();
        if (destinationMapData) {
          this.destinationData.set(destinationMapData);
          this.isLoading.set(false);
        } else {
          this.destinationData.set([]);
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  onShapeCreated(e: ShapeCreatedEvent): void {
    const shape = e.shape;
    const users = e.dataItem.properties.users;
    if (users) {
      const opacity = users / 1000;
      shape.options.set('fill.opacity', opacity);
    }
  }

  onContextMenuSelect(event: any) {
    if (event?.text === 'View Data') {
      this.gridService.setDateRange(this.dateRange());
      this.gridService.url = `${environment.apiUrl}/v1/analytics/administrative/slaviolationsdetails(${this.organizationId()})`;
      this.gridService.read();
      this.showPopup = true;
    } else {
      console.warn('Invalid event structure:', event);
    }
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };

  // Close popup
  close() {
    this.showPopup = false;
  }
}
