import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
export function placeholder(message) {
  const decAttrs = {
    class: 'k-placeholder',
    'data-placeholder': message
  };
  return new Plugin({
    key: new PluginKey('placeholder'),
    props: {
      decorations: state => {
        const {
          doc
        } = state;
        const firstChild = doc.content.firstChild;
        const empty = doc.childCount === 0 || doc.childCount === 1 && firstChild.inlineContent && firstChild.childCount === 0;
        if (!empty) {
          return DecorationSet.empty;
        }
        const decorations = [];
        doc.descendants((node, pos) => {
          decorations.push(Decoration.node(pos, pos + node.nodeSize, decAttrs));
        });
        return DecorationSet.create(doc, decorations);
      }
    }
  });
}