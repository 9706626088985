import { Component } from '@angular/core';
import { ChartsModule, SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-threat-status',
  standalone: true,
  imports: [ChartsModule],
  templateUrl: './threat-status.component.html',
  styleUrl: './threat-status.component.scss',
})
export class ThreatStatusComponent {
  public data = [
    {
      category: 'mitigated',
      title: 'Mitigated',
      value: 1.0,
      count: 80,
    },
    {
      category: 'not_mitigated',
      title: 'Not Mitigated',
      value: 1.0,
      count: 13,
    },
  ];

  public labelContent(e: SeriesLabelsContentArgs): string {
    return e.category;
  }
}
