import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild, ViewEncapsulation, effect, inject, signal } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DrawerItem, DrawerSelectEvent, LayoutModule } from '@progress/kendo-angular-layout';
import { IconsModule } from '@progress/kendo-angular-icons';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { SVGIcon, bellIcon, homeIcon, userIcon, clipboardTextIcon, gearsIcon, bookIcon, chevronDoubleLeftIcon, chevronDoubleRightIcon, chevronDownIcon } from '@progress/kendo-svg-icons';
import { CommonModule, DOCUMENT } from '@angular/common';
import { DrawerStateService } from '../../../core/services/drawerstate.service';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { User } from '../../../core/models/user';
import { OrganizationService } from '../organizations/services/organization.service';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { catchError, filter, of, Subscription, takeUntil } from 'rxjs';
import { LogoutService } from '../../../core/services/logout.service';
import { EventSourceService } from '../../../core/services/event-source.service';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { NotificationsComponent } from '../../customer/layout/components/notifications/notifications.component';
import { VirtualDropdownComponent } from '../../../core/components/virtual-dropdown/virtual-dropdown.component';
import { Organization } from '../../../core/models/entities';
import { EventService } from '../../../core/services/event.service';

interface ExtendedDrawerItem extends DrawerItem {
  svgIcon?: SVGIcon;
  path?: string;
  selected?: boolean;
}

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, LayoutModule, ButtonsModule, IconsModule, NavigationModule, IndicatorsModule, DropDownListModule, VirtualDropdownComponent],
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OrganizationService],
})
export class AdminLayoutComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('virtualDropdown', { static: false }) orgDropDown: VirtualDropdownComponent<Organization> | undefined;
   
  private user = inject(User);
  private popupService = inject(PopupService);
  public router = inject(Router);
  public orgService = inject(OrganizationService);
  public logoutService = inject(LogoutService);
  public eventSourceService = inject(EventSourceService);
  public eventService = inject(EventService);
  notifications = signal<any[]>([]);

  avatarText: string | null = null;

  public orgs = signal<{ id: number; name: string }[]>([]);
  public selected: ExtendedDrawerItem | undefined;
  public chevronDoubleLeftIcon: SVGIcon = chevronDoubleLeftIcon;
  public chevronDoubleRightIcon: SVGIcon = chevronDoubleRightIcon;
  public bellIcon: SVGIcon = bellIcon;
  public gearsIcon: SVGIcon = gearsIcon;
  public chevronDownIcon: SVGIcon = chevronDownIcon;
  public userIcon: SVGIcon = userIcon;
  private popupRef: PopupRef | undefined;
  bodyElement: ElementRef;

  public data: Array<any> = [
    // { text: 'My Profile', icon: 'user' },
    { text: 'Logout', icon: 'log-out' },
  ];

  public Items: Array<ExtendedDrawerItem> = [
    {
      text: 'Dashboard',
      svgIcon: homeIcon,
      path: '/admin/dashboard',
    },
    { text: 'Organizations', svgIcon: bellIcon, path: '/admin/organizations' },
    { text: 'Users', svgIcon: userIcon, path: '/admin/users' },
    {
      text: 'Advisories',
      svgIcon: clipboardTextIcon,
      path: '/admin/advisories',
    },
    { text: 'Detections Manager', svgIcon: gearsIcon, path: '/admin/manager' },
    { text: 'System Log', svgIcon: bookIcon, path: '/admin/syslog' },
    { text: 'ONE Roadmap', svgIcon: bookIcon, path: '/admin/roadmap' },
  ];

  public drawerStateService = inject(DrawerStateService);

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();
    this.removeChatWidget();
    this.initEffect();
    this.setUserAvatarText();
    this.bodyElement = new ElementRef(this.document.body);
  }

  ngAfterViewInit() {
    const defaultOrg = { id: 0, name: 'Select Organization', account_type: null };
    this.orgs.set([defaultOrg]);
    //this.orgDropDown!.defaultItem = defaultOrg;
    // this.loadFrillWidget();
  }

  public viewNotifications(): void {
    this.togglePopup();
  }

  private setUserAvatarText() {
    const user = this.user;
    const firstName = user.first_name || '';
    const lastName = user.last_name || '';

    if (firstName && lastName) {
      this.avatarText = `${firstName.charAt(0)}${lastName.charAt(0)}`;
    } else {
      this.avatarText = null;
    }
  }

  private initEffect() {
    effect(() => {
      this.router.events
        .pipe(
          filter((event): event is NavigationEnd => event instanceof NavigationEnd),
          filter((event: NavigationEnd) => !event.urlAfterRedirects.includes('auth')),
        )
        .subscribe((event: NavigationEnd) => {
          this.updateSelectedItem(event.urlAfterRedirects);
        });

      this.eventSourceService.connectToServerSentEvents(['message']).subscribe({
        next: (response: any) => {
          const data = JSON.parse(response.data);
          if (data.id) {
            this.notifications.update((x) => [...x, data]);
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    });
  }

  private updateSelectedItem(url: string): void {
    const path = url.split('?')[0]; // Strip out query parameters
    const index = this.Items.findIndex((item) => item.path === path);

    if (index > -1) {
      this.Items = this.Items.map((item, i) => {
        item.selected = false;
        if (i === index) {
          return { ...item, selected: true };
        } else {
          return item;
        }
      });
    }
  }

  public onUserProfileClick(event: any): void {
    if (event.text === 'Logout') {
      this.logoutService
        .globalSignOut(this.user.id)
        .pipe(
          takeUntil(this.destroyed$),
          catchError((error) => {
            console.error('Error during logout', error);
            return of(null);
          }),
        )
        .subscribe(() => {
          this.user.clearUserData();
          this.router.navigate(['/auth/login']);
        });
    } else if (event.text === 'My Profile') {
      // Implement profile navigation or action here
    }
  }

  onOrgDropdownChanged($event: any) {
    const currentRoute = this.router.url;
    let targetRoute = '';

    if (currentRoute.includes('/customer')) {
      targetRoute = `/customer/customer-dashboard/`;
    } else if (currentRoute.includes('/admin')) {
      targetRoute = `/customer/customer-dashboard/`;
    } else {
      // Default or other routes
      targetRoute = `/admin/dashboard`;
    }
    
    this.router.navigate([targetRoute], { queryParams: { organizationId: $event.id, account_type: $event.account_type } });
    this.eventService.orgChange($event);
  }

  public onSelect(ev: DrawerSelectEvent): void {
    const item = ev.item as ExtendedDrawerItem;
    if (item.path) {
      this.router.navigate([item.path]);
    }

    this.Items.forEach((item) => {
      if (item.text) {
        item.selected = false;
      }
    });
    const selectedItem = ev.item;
    selectedItem.selected = true;
    this.selected = selectedItem.text;
  }

  toggleDrawer(): void {
    this.drawerStateService.toggleDrawer();
  }

  addFrillScript(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//widget.frill.co/v2/widget.js';
    script.defer = true;
    document.body.appendChild(script);
  }

  public togglePopup(): void {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = undefined;
    } else {
      this.popupRef = this.popupService.open({
        anchor: this.bodyElement,
        content: NotificationsComponent,
        animate: { type: 'slide', direction: 'left', duration: 200 },
        anchorAlign: { horizontal: 'right', vertical: 'top' },
        popupAlign: { horizontal: 'right', vertical: 'top' },
      });

      const notificationsComponent = this.popupRef.content.instance as NotificationsComponent;

      notificationsComponent.notifications.set(this.notifications());

      notificationsComponent.close.subscribe(() => {
        this.notifications.set(notificationsComponent.notifications());
        this.togglePopup();
      });
    }
  }

  removeChatWidget(): void {
    const chatWidget = document.getElementById('zohohc-asap-web-helper-main');
    if (chatWidget) {
      chatWidget.remove();
    }
  }
}
