import { Component, CreateEffectOptions, Input, Signal, effect, inject, input, signal } from '@angular/core';
import { ChartsModule, SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { AnalyticsService, TopFiveData } from '../../../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { Observable } from 'rxjs';
import { ExcelModule, GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { environment } from '../../../../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { MenusModule } from '@progress/kendo-angular-menu';
import { EscalationRelevancyDetailsService } from './services/escalationrelevancydetails.service';

@Component({
  selector: 'app-outcome-status',
  standalone: true,
  imports: [ChartsModule, LoaderModule, CommonModule, DialogsModule, GridModule, ExcelModule, MenusModule],
  templateUrl: './outcome-status.component.html',
  styleUrl: './outcome-status.component.scss',
  providers: [AnalyticsService, EscalationRelevancyDetailsService],
})
export class OutcomeStatusComponent extends BaseComponent {
  public gridService = inject(EscalationRelevancyDetailsService);
  public data = input<TopFiveData[]>([]);

  public organizationId = input<number | null>();
  public dateRange = input<string[] | null>(null);
  public truePositivePercentage: number = 0;
  public falsePositivePercentage: number = 0;
  public unknownPercentage: number = 0;
  public autofit = true;
  public isLoading = signal<boolean>(true);
  public items: any[] = [{ text: 'View Data' }];
  public showPopup = false;

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const data = this.data();
        if (data) {
          this.calculatePercentages();
          this.isLoading.set(false);
        } else {
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  onContextMenuSelect(event: any) {
    if (event?.text === 'View Data') {
      this.gridService.setDateRange(this.dateRange());
      this.gridService.url = `${environment.apiUrl}/v1/analytics/administrative/escalationrelevancydetails(${this.organizationId()})`;
      this.gridService.read();
      this.showPopup = true;
    } else {
      console.warn('Invalid event structure:', event);
    }
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };

  // Close popup
  close() {
    this.showPopup = false;
  }

  private calculatePercentages() {
    const total = this.data().reduce((sum, item) => sum + item.count, 0);

    if (total > 0) {
      const truePositive = this.data().find((item) => item.description === 'True Positive');
      const falsePositive = this.data().find((item) => item.description === 'False Positive');
      const unknown = this.data().find((item) => item.description === 'Unknown');

      this.truePositivePercentage = truePositive ? (truePositive.count / total) * 100 : 0;
      this.falsePositivePercentage = falsePositive ? (falsePositive.count / total) * 100 : 0;
      this.unknownPercentage = unknown ? (unknown.count / total) * 100 : 0;
    } else {
      this.truePositivePercentage = 0;
      this.falsePositivePercentage = 0;
      this.unknownPercentage = 0;
    }
  }

  public labelContent(e: SeriesLabelsContentArgs): string {
    const percentage = (e.value * 100).toFixed(0); // Convert value to percentage and format to two decimal places
    return `${e.category}: \n ${percentage}%`;
  }
}
