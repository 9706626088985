<div class="date-filter">
  <div>
    <app-cyflare-period
      [(dateRange)]="selectedDateRange"
      label="Filter Analytics:"
    ></app-cyflare-period>
  </div>
</div>

<div class="container p-3">
  <div class="py-3">
    <div class="row g-3">
      <div class="col-lg-12">
        <app-hud></app-hud>
      </div>
    </div>
  </div>

  <!-- Add the new row for Agent Status -->
  <div class="row g-3">
    <div class="col-lg-6 col-md-12">
      <div class="tile">
        <div class="tile-title">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="flex: 1; display: flex; justify-content: center">
              <h5>
                Agent Status
                <span
                  kendoTooltip
                  tooltipClass="custom-tooltip"
                  title="Current status distribution of all EDR agents"
                >
                  <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                </span>
              </h5>
            </div>
          </div>
        </div>
        <app-agent-status></app-agent-status>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="tile">
        <div class="tile-title">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="flex: 1; display: flex; justify-content: center">
              <h5>
                Detected Threat Status
                <span
                  kendoTooltip
                  tooltipClass="custom-tooltip"
                  title="Current status distribution of all EDR agents"
                >
                  <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                </span>
              </h5>
            </div>
          </div>
        </div>
        <app-threat-status></app-threat-status>
      </div>
    </div>
  </div>
</div>
