<kendo-chart [transitions]="true">
  <kendo-chart-series>
    <kendo-chart-series-item
      [autoFit]="true"
      type="pie"
      [data]="data"
      categoryField="title"
      field="count"
    >
      <kendo-chart-series-item-labels
        position="outsideEnd"
        color="#000"
        [content]="labelContent"
      >
      </kendo-chart-series-item-labels>
    </kendo-chart-series-item>
  </kendo-chart-series>
  <kendo-chart-legend [visible]="false"></kendo-chart-legend>
</kendo-chart>
