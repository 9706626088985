import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { SocComponent } from './components/soc/soc.component';
import { MitreAttackComponent } from '../mitreattack/mitreattack.component';
import { EdrComponent } from './components/edr/edr.component';
import { VssComponent } from './components/vss/vss.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-analytics',
  standalone: true,
  imports: [LayoutModule, ReactiveFormsModule, SocComponent, MitreAttackComponent, EdrComponent, VssComponent],
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent extends BaseComponent {}
