import { Component, ComponentRef, effect, inject, signal, Type } from '@angular/core';
import { OrgAppbarComponent } from '../../../layout/components/org-appbar/org-appbar.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ConnectwiseComponent } from './components/connectwise/connectwise.component';
import { ServicenowComponent } from './components/servicenow/servicenow.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { PopupHostComponent } from '../../../../../core/components/popup-host.component';
import { IngestorIntegration, IntegrationService } from '../../../../../core/services/integration.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { combineLatestWith, map } from 'rxjs';
import { IngestorSourcesComponent } from './components/ingestors/ingestor-sources/ingestor-sources.component';

type IntegrationType = 'connectWise' | 'serviceNow';

@Component({
  selector: 'app-integrations',
  standalone: true,
  imports: [OrgAppbarComponent, LayoutModule, IndicatorsModule, PopupModule, ButtonsModule, CommonModule, DialogsModule, ServicenowComponent, ConnectwiseComponent, IngestorSourcesComponent],
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss',
  providers: [IntegrationService],
})
export class IntegrationsComponent extends PopupHostComponent {
  private integrationService = inject(IntegrationService);
  private route = inject(ActivatedRoute);

  show: boolean | undefined;
  activeIntegration = signal<any | null>(null);
  selectedIntegrationType: any;
  selectedIngestorType: any;
  componentToLoad: ComponentRef<any> | undefined;
  organizationId: number;
  saveConfirmDialog = false;
  ingestorIntegrations = signal<IngestorIntegration[]>([]);

  constructor() {
    super();
    const queryParams = this.route.snapshot.queryParams;
    this.organizationId = parseInt(queryParams['organizationId']);
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      this.getActiveIntegration();
      this.getIngestors();
    });
  }

  getActiveIntegration() {
    this.integrationService
      .getActiveIntegration(this.organizationId)
      .pipe(combineLatestWith(this.integrationService.getActiveIntegrationFieldValues(this.organizationId)))
      .subscribe({
        next: ([activeIntegrationResponse, fieldMappingResponse]: any) => {
          if (activeIntegrationResponse && activeIntegrationResponse.pk) {
            activeIntegrationResponse.fieldMappings = fieldMappingResponse;
            this.activeIntegration.set(activeIntegrationResponse);
            this.selectedIntegrationType = this.getIntegrations().find((x) => x.integrationType.toLowerCase() == activeIntegrationResponse.ticket_integration_name.toLowerCase());
          }
        },
        error: (error) => {
          console.error('Error fetching existing connection:', error);
        },
      });
  }

  isIntegrationActive(integrationName: IntegrationType) {
    return this.activeIntegration()?.ticket_integration_name.toLowerCase() == integrationName.toLowerCase();
  }

  isDisabled(integrationName: IntegrationType) {
    return this.activeIntegration()?.ticket_integration_name && !this.isIntegrationActive(integrationName);
  }

  onSave() {
    this.openSaveConfirmDialog();
    setTimeout(() => {
      this.getActiveIntegration();
    }, 600);
  }

  getIntegrations() {
    return [
      {
        integrationType: 'connectWise',
        title: 'ConnectWise',
        component: ConnectwiseComponent,
      },
      {
        integrationType: 'serviceNow',
        title: 'ServiceNow',
        component: ServicenowComponent,
      },
    ] as { integrationType: IntegrationType; title: string; component: Type<any> }[];
  }

  getIngestors(){
    this.integrationService.getIngestorIntegrations()
    .pipe(map((data) => {
      return data.map(x => ({
        id: x.id,
        name: x.name,
        icon: x.icon.replace(/^.*media\//, 'assets/logos/'),
        component: IngestorSourcesComponent,
      }));
    }))
    .subscribe((data) => {
      this.ingestorIntegrations.set(data);
    });
  }

  public addIntegration(integrationType: IntegrationType): void {
    this.selectedIngestorType = undefined;
    const integrationMap = this.getIntegrations();
    if (!this.isDisabled(integrationType)) {
      this.selectedIntegrationType = integrationMap.find((x) => x.integrationType == integrationType);
    }
  }

  public ingestorIntegrationClick(injestorIntegration: IngestorIntegration): void {
    this.selectedIntegrationType = undefined;
    this.selectedIngestorType = injestorIntegration;
  }

  public openSaveConfirmDialog(): void {
    this.saveConfirmDialog = true;
  }

  public closeDialog(status: string): void {
    this.saveConfirmDialog = false;
  }
}

export interface IngestorIntegrationWithComponent extends IngestorIntegration {
  component?: Type<Component>;
}