import { Component, Input, ViewChild, effect, inject, signal } from '@angular/core';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GridModule, PDFModule, ExcelModule, PDFService, ContextService, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { O365EventsService } from '../../../../../../../core/services/o365-events.service';

@Component({
  selector: 'app-o365-email-compromise',
  standalone: true,
  imports: [GridModule, CommonModule, LoaderModule, PDFModule, ExcelModule],
  templateUrl: './o365-email-compromise.component.html',
  styleUrl: './o365-email-compromise.component.scss',
  providers: [O365EventsService, PDFService, ContextService],
})
export class O365EmailCompromiseComponent extends BaseComponent {
  @ViewChild('grid', { static: false }) grid!: GridComponent;

  private sanitizer = inject(DomSanitizer);
  headerHtml!: SafeHtml;
  @Input() set htmlContent(value: string) {
    this.headerHtml = this.sanitizer.bypassSecurityTrustHtml(value);
  }
  public landscape = false;
  public fileName: string = '';

  public gridService = inject(O365EventsService);
  private route = inject(ActivatedRoute);
  public organizationId: number | null = null;

  public selectedDateRange = signal<string[]>([]);
  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      this.route.queryParams.subscribe((params) => {
        this.organizationId = params['organizationId'];
      });
      this.applyFilter();
    });
  }

  public exportPDF(fileName: string) {
    this.fileName = `${fileName}.pdf`;
    setTimeout(() => {
      this.grid.saveAsPDF();
    }, 100);
  }

  public exportExcel(fileName: string) {
    this.fileName = `${fileName}.xlsx`;
    this.grid.saveAsExcel();
  }

  applyFilter() {
    const dateRange = this.selectedDateRange();
    if (!dateRange) return;
    this.filter = {
      logic: 'and',
      filters: [],
    };
    const field = 'timestamp';

    // Adding date range filter
    this.filter.filters.push({
      field: field,
      operator: 'gt',
      value: dateRange[0],
    });

    this.filter.filters.push({
      field: field,
      operator: 'lt',
      value: dateRange[1],
    });

    this.filter.filters.push({
      logic: 'or',
      filters: [
        { field: 'operation', operator: 'eq', value: 'Set-Mailbox' },
        { field: 'operation', operator: 'eq', value: 'New-InboxRule' },
        { field: 'operation', operator: 'eq', value: 'Release-QuarantineMessage' },
        { field: 'operation', operator: 'eq', value: 'New-TransportRule' },
      ],
    });

    // Update the grid filter state
    this.gridService.state.filter = {
      logic: 'and',
      filters: this.filter.filters,
    };

    // Execute the updated filter
    this.gridService.getO365Events(this.organizationId);
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };
}
