import { AddMarkStep, RemoveMarkStep } from 'prosemirror-transform';
import { TextSelection } from 'prosemirror-state';
import { changeStylesString, shallowEqual } from './utils';
import { markApplies, hasMark, removeMarks, toggleMark, styleValue, selectionMarks } from './mark';
const changeStyleFromMark = (marks, toChange) => {
  const styleMark = marks.find(m => m.type.name === 'style');
  const elementStyle = styleMark && styleMark.attrs.style;
  return changeStylesString(elementStyle, toChange);
};
const changeStyleMark = (tr, from, to, attrs, markType) => {
  const mark = markType.create({
    style: attrs.style
  });
  let removed = [],
    added = [],
    removing = null,
    adding = null;
  tr.doc.nodesBetween(from, to, (node, pos, parent) => {
    if (!node.isInline) {
      return;
    }
    const marks = node.marks;
    if (!mark.isInSet(marks) && parent.type.allowsMarkType(mark.type)) {
      const start = Math.max(pos, from),
        end = Math.min(pos + node.nodeSize, to);
      const newStyle = changeStyleFromMark(marks, attrs);
      if (newStyle.changed || attrs.newValue) {
        const style = newStyle.changed ? {
          style: newStyle.style || null
        } : {
          style: `${[attrs.style]}: ${attrs.newValue};`
        };
        const currentMark = markType.isInSet(marks) ? marks.find(m => m.type.name === 'style') : null;
        const newMarkAttrs = currentMark ? Object.assign(Object.assign({}, currentMark.attrs), style) : style;
        const newStyleMark = markType.create(newMarkAttrs);
        const newSet = newStyleMark.addToSet(marks);
        for (let i = 0; i < marks.length; i++) {
          if (!marks[i].isInSet(newSet)) {
            if (removing && removing.to === start && removing.mark.eq(marks[i])) {
              removing.to = end;
            } else {
              removing = new RemoveMarkStep(start, end, marks[i]);
              removed.push(removing);
            }
          }
        }
        const previousAdded = adding && adding.to === start;
        const sameAdding = previousAdded && newStyleMark.attrs.style === adding.mark.attrs.style;
        if (previousAdded && sameAdding) {
          adding.to = end;
        } else if (Object.keys(newMarkAttrs).some(attrName => newMarkAttrs[attrName] !== null)) {
          adding = new AddMarkStep(start, end, newStyleMark);
          added.push(adding);
        }
      }
    }
  });
  removed.forEach(s => tr.step(s));
  added.forEach(s => tr.step(s));
  return removed.length + added.length > 0;
};
/**
 * Used by FontSize and FontName tools for getting their state.
 */
export const getInlineStyles = (state, style) => {
  const styleMark = state.schema.marks.style;
  const marks = styleMark ? selectionMarks(state, styleMark) : [];
  return marks.map(mark => styleValue(mark, style)).filter(m => m !== null);
};
const changeStyle = (markType, attrs) => {
  return function (state, dispatch, tr) {
    const {
      empty,
      ranges
    } = state.selection;
    const $cursor = state.selection instanceof TextSelection && state.selection.$cursor;
    if (empty && !$cursor || !markApplies(state.doc, ranges, markType)) {
      return false;
    }
    let result = false;
    if (dispatch) {
      const transaction = tr || state.tr;
      if ($cursor) {
        const currentMarks = state.storedMarks || $cursor.marks();
        if (markType.isInSet(currentMarks)) {
          const newStyle = changeStyleFromMark(currentMarks, attrs);
          const styleMark = currentMarks.find(m => m.type.name === 'style');
          const newAttrs = Object.assign(Object.assign({}, styleMark ? styleMark.attrs : {}), {
            style: newStyle.style || null
          });
          if (shallowEqual(styleMark.attrs, newAttrs)) {
            return false;
          }
          dispatch(transaction.removeStoredMark(markType));
          if (Object.keys(newAttrs).some(attrName => newAttrs[attrName] !== null)) {
            dispatch(transaction.addStoredMark(markType.create(newAttrs)));
          }
          result = true;
        }
      } else {
        for (let i = 0; i < ranges.length; i++) {
          const {
            $from,
            $to
          } = ranges[i];
          result = changeStyleMark(transaction, $from.pos, $to.pos, attrs, markType) || result;
        }
        if (result) {
          transaction.scrollIntoView();
          dispatch(transaction);
        }
      }
    }
    return result;
  };
};
/**
 * Used by bold, italic, ... and link commands.
 */
export const toggleInlineFormat = (options, tr, markAttrs) => (state, dispatch) => {
  const marks = state.schema.marks;
  const {
    altStyle,
    altMarks = [],
    mark
  } = options;
  const transaction = tr || state.tr;
  let styleRemoved = false;
  let dispatched = false;
  const markDispatched = () => dispatched = true;
  if (altStyle && marks.style) {
    const cmd = changeStyle(marks.style, {
      style: altStyle.name,
      value: altStyle.value
    });
    styleRemoved = cmd(state, markDispatched, transaction);
  }
  const allMarks = [mark, ...altMarks].filter(m => marks[m]);
  const toRemove = allMarks.map(m => hasMark(state, {
    mark: m
  }) && marks[m]).filter(m => m);
  if (toRemove.length) {
    removeMarks(toRemove, state, markDispatched, transaction);
  } else {
    if (!styleRemoved) {
      toggleMark(marks[mark], markAttrs, transaction)(state, markDispatched);
    }
  }
  if (dispatched) {
    dispatch(transaction);
  }
  return dispatched;
};
/**
 * Used by FontSize, FontName, Color and BackColor commands.
 */
export const applyInlineStyle = (options, command) => (state, dispatch) => {
  const marks = state.schema.marks;
  const markType = marks.style;
  const attrs = {
    style: options.style,
    value: /^.+$/,
    newValue: options.value
  };
  const tr = state.tr;
  if (command) {
    tr.setMeta('commandName', command);
  }
  tr.setMeta('args', options);
  const {
    empty,
    $cursor,
    ranges
  } = state.selection;
  if (empty && !$cursor || !markType || !markApplies(state.doc, ranges, markType)) {
    return false;
  }
  // Empty selection
  if ($cursor) {
    const marksFromSelection = state.storedMarks || $cursor.marks();
    const currentMark = markType.isInSet(marksFromSelection) ? marksFromSelection.find(m => m.type.name === 'style') : null;
    const newStyles = {
      style: null
    };
    if (currentMark && currentMark.attrs.style) {
      const resultStyles = changeStylesString(currentMark.attrs.style, attrs);
      if (resultStyles.changed && resultStyles.style) {
        newStyles.style = resultStyles.style;
      }
    } else if (attrs.newValue) {
      newStyles.style = `${[attrs.style]}: ${attrs.newValue};`;
    }
    const newMarkAttrs = currentMark ? Object.assign(Object.assign({}, currentMark.attrs), newStyles) : newStyles;
    if (Object.keys(newMarkAttrs).some(attrName => newMarkAttrs[attrName] !== null)) {
      dispatch(tr.addStoredMark(markType.create(newMarkAttrs)));
    } else {
      dispatch(tr.removeStoredMark(markType));
    }
    return true;
  }
  return changeStyle(markType, attrs)(state, dispatch, tr);
};