import { CommonModule } from '@angular/common';
import { Component, CreateEffectOptions, Input, Signal, effect, inject, input, output, signal } from '@angular/core';
import { ChartsModule, SeriesClickEvent } from '@progress/kendo-angular-charts';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { DispositionData } from '../../../core/services/analytics.service';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { ChartLabelsPopupService } from '../../../core/shared/common/chart-labels-popup.service';
import { DetectionDispositionDetailsService } from '../../customer/analytics/components/soc/components/sla-adherence/services/detectionDispositiondetails.service';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule, ExcelModule, GridDataResult } from '@progress/kendo-angular-grid';
import { MenusModule } from '@progress/kendo-angular-menu';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-disposition-column',
  standalone: true,
  imports: [ChartsModule, LoaderModule, CommonModule, GridModule, DialogsModule, ExcelModule, MenusModule],
  templateUrl: './disposition-column.component.html',
  styleUrl: './disposition-column.component.scss',
  providers: [DetectionDispositionDetailsService],
})
export class DispositionColumnComponent extends BaseComponent {
  public gridService = inject(DetectionDispositionDetailsService);
  public popupService = inject(ChartLabelsPopupService);
  @Input() dispositionData!: Signal<DispositionData[]>;

  public organizationId = input<number | null>();
  public dateRange = input<string[] | null>(null);

  public isLoading = signal(true);
  public showPopup = false;
  public detectionDisposition = signal<DispositionData[]>([]);
  public onSeriesClick = output<SeriesClickEvent>();

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const dispositionData = this.dispositionData();
        if (dispositionData) {
          this.detectionDisposition.set(dispositionData || []);
          this.isLoading.set(false);
        } else {
          this.detectionDisposition.set([]);
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  public handleSeriesClick(event: SeriesClickEvent): void {
    this.onSeriesClick.emit(event);
  }

  truncateCategoryLabel(args: any): string {
    const label = args.text;
    const maxLength = 20; // Set your desired max length
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  }

  onContextMenuSelect(event: any) {
    if (event?.text === 'View Data') {
      this.gridService.setDateRange(this.dateRange());
      this.gridService.url = `${environment.apiUrl}/v1/analytics/administrative/slaviolationsdetails(${this.organizationId()})`;
      this.gridService.read();
      this.showPopup = true;
    } else {
      console.warn('Invalid event structure:', event);
    }
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };

  // Close popup
  close() {
    this.showPopup = false;
  }
}
