import { ChangeDetectorRef, ChangeDetectionStrategy, Component, input, signal, OnInit, ViewChild, output, effect, model, inject } from '@angular/core';
import { DropDownListComponent, DropDownsModule, PreventableEvent, VirtualizationSettings } from '@progress/kendo-angular-dropdowns';
import { IconsModule } from '@progress/kendo-angular-icons';
import { chevronDownIcon } from '@progress/kendo-svg-icons';
import { ODataService } from '../../services/odata.service';
import { Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../shared/common/base.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cyflare-virtual-dropdown',
  standalone: true,
  imports: [CommonModule, DropDownsModule, IconsModule],
  templateUrl: './virtual-dropdown.component.html',
  styleUrls: ['./virtual-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: VirtualDropdownComponent,
      multi: true,
    },
  ],
})
export class VirtualDropdownComponent<T> extends BaseComponent implements OnInit, ControlValueAccessor {
  @ViewChild('dropdownList', { static: false }) dropdownList: DropDownListComponent | undefined;

  private cdr = inject(ChangeDetectorRef);
  public sortField = input<string>('name');
  public sortOrder = input<any>('asc');
  public valueField = input<string>('id');
  public textField = input<string>('name');
  public filterField = input<string>('name');
  public refreshOnClick = input<boolean>(false);
  public dataService = input.required<ODataService<T>>();
  public styles = input<Record<string, any>>({ width: '225px' });
  public valueChanged = output<T>();
  public filterable = input<boolean>(false);
  public virtual = input<boolean | VirtualizationSettings>();
  public value = model<T | undefined>();
  public mapper = input<(data: any[]) => any[]>();
  public defaultItem = input<any>({ id: 0, name: 'Select...' });
  public valuePrimitive = input<boolean>(false);
  public filterLength = input<number>(3);

  public data = signal<T[]>([]);
  public chevronDownIcon = chevronDownIcon;
  private page = 0;
  private totalRecords = 0;
  private pageSize = 10;
  private scrollSubscription: Subscription | null = null;
  private isDisabled = false;

  private onChange: (value: T | undefined) => void = () => {};
  private onTouched: () => void = () => {};

  private router = inject(Router);
  private route = inject(ActivatedRoute);

  constructor() {
    super();
    this.initEffects();
  }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit(): void {
    this.applyDisabledState();
  }

  ngOnChanges(): void {
    this.applyDisabledState();
  }

  private applyDisabledState(): void {
    if (this.dropdownList) {
      this.dropdownList.disabled = this.isDisabled;
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  private initEffects(): void {
    effect(() => {
      const defaultItem = this.defaultItem();
      if (defaultItem && this.dropdownList) {
        this.dropdownList.defaultItem = defaultItem;
      }
    });
  }

  addOrRemoveFilter(filter: any) {
    let currentFilter = this.dataService().state.filter;
    if (!currentFilter) {
      currentFilter = { logic: 'and', filters: [] };
    }

    const index = currentFilter.filters.findIndex((f: any) => f.field === filter.field);

    if (index === -1) {
      currentFilter.filters.push(filter);
    } else if (filter.value) {
      currentFilter.filters[index] = filter;
    } else {
      currentFilter.filters.splice(index, 1);
    }

    this.dataService().state = {
      skip: 0,
      take: this.pageSize,
      filter: currentFilter,
      sort: [
        {
          field: this.sortField(),
          dir: this.sortOrder(),
        },
      ],
    };
  }

  onDropdownFilterChange(value: any) {
    if (value.length >= this.filterLength()) {
      this.addOrRemoveFilter({
        field: this.filterField(),
        operator: 'contains',
        value: value,
      });
      this.page = 0;
      this.getData();
    } else if (value.length === 0) {
      this.addOrRemoveFilter({
        field: this.filterField(),
        operator: 'contains',
      });
      this.page = 0;
      this.getData();
    }
  }

  onDropdownChange(event: T) {
    this.value.set(event);
    this.valueChanged.emit(event);
    this.onChange(event);
    this.onTouched();

    // If we're on the detection-settings route, emit a special event
    if (this.router.url.includes('detection-settings')) {
      const selectedOrgId = (event as any)[this.valueField()];
      console.log('Selected Organization:', event); // Debug log
      console.log('Value Field:', this.valueField()); // Debug log
      console.log('Selected Org ID:', selectedOrgId); // Debug log

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { organizationId: selectedOrgId, refresh: new Date().getTime() },
        queryParamsHandling: 'merge',
      });
    }
  }

  onDropdownOpened($event: T) {
    console.log('onOrgDropdownOpened', $event);
    this.subscribeToScroll();
  }

  onDropdownOpen(event: PreventableEvent) {
    // if (this.refreshOnClick()) this.getData();
  }

  private subscribeToScroll(): void {
    if (this.dropdownList?.optionsList?.popupListScroll) {
      // Unsubscribe from any previous subscriptions to avoid memory leaks and duplicate subscriptions
      this.scrollSubscription?.unsubscribe();

      this.scrollSubscription = this.dropdownList.optionsList.popupListScroll
        .pipe(
          tap(() => console.log('Scrolling')),
          takeUntil(this.destroyed$),
        )
        .subscribe(this.onScroll.bind(this));
    }
  }

  private getData(callback?: () => void) {
    console.log('getData');
    (this.dataService().state.sort = [
      {
        field: this.sortField(), // The field to sort by
        dir: this.sortOrder(), // Sorting direction: 'asc' for ascending, 'desc' for descending
      },
    ]),
      this.dataService().read((response) => {
        this.totalRecords = response.total;

        if (this.mapper()) {
          response.data = this.mapper()!(response.data);
        }

        this.data.set(response.data as T[]);
        if (callback) {
          callback();
        }
      });
  }

  onScroll(event: any) {
    console.log('onScroll', event);
    const listContainer = event.target as HTMLElement;
    const scrollTop = listContainer.scrollTop;
    const scrollHeight = listContainer.scrollHeight;
    const offsetHeight = listContainer.offsetHeight;

    if (scrollTop + offsetHeight >= scrollHeight && this.data().length < this.totalRecords) {
      this.page++;

      this.dataService().state.skip = this.page * this.pageSize;
      this.dataService().state.take = this.pageSize;

      this.dataService().read((response) => {
        this.totalRecords = response.total;

        if (this.mapper()) {
          response.data = this.mapper()!(response.data);
        }

        this.data.update((existingItems) => [...existingItems, ...response.data] as T[]);
      });
    }
  }

  writeValue(obj: T | undefined): void {
    Promise.resolve().then(() => {
      this.value.set(obj);
    });
  }

  registerOnChange(fn: (value: T | undefined) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.applyDisabledState();
  }
}
