import { Component } from '@angular/core';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';

@Component({
  selector: 'app-subscriptions',
  standalone: true,
  imports: [SwitchModule, LabelModule],
  templateUrl: './subscriptions.component.html',
  styleUrl: './subscriptions.component.scss',
})
export class SubscriptionsComponent {
  // Event handlers for switch changes
  onXdrChange(checked: boolean): void {
    console.log('XDR switch changed:', checked);
    // Implement your XDR switch logic here
  }

  onEdrChange(checked: boolean): void {
    console.log('EDR switch changed:', checked);
    // Implement your EDR switch logic here
  }

  onVssChange(checked: boolean): void {
    console.log('VSS switch changed:', checked);
    // Implement your VSS switch logic here
  }

  onEsChange(checked: boolean): void {
    console.log('ES switch changed:', checked);
    // Implement your ES switch logic here
  }

  onNetworkDiscoveryChange(checked: boolean): void {
    console.log('Network Discovery switch changed:', checked);
    // Implement your Network Discovery switch logic here
  }

  onLogRetentionChange(checked: boolean): void {
    console.log('Extended Log Retention switch changed:', checked);
    // Implement your Log Retention switch logic here
  }

  onDarkwebChange(checked: boolean): void {
    console.log('Darkweb Monitoring switch changed:', checked);
    // Implement your Darkweb Monitoring switch logic here
  }

  onFinancialRiskChange(checked: boolean): void {
    console.log('Financial Risk Analysis switch changed:', checked);
    // Implement your Financial Risk Analysis switch logic here
  }

  onWebFilteringChange(checked: boolean): void {
    console.log('Web Filtering switch changed:', checked);
    // Implement your Web Filtering switch logic here
  }

  onSspmChange(checked: boolean): void {
    console.log('SSPM switch changed:', checked);
    // Implement your SSPM switch logic here
  }
}
