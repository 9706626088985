@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else {
  <kendo-chart
    style="height: 450px"
    (render)="onRender($event)"
  >
    <kendo-chart-series>
      <kendo-chart-series-item
        type="donut"
        [data]="chartData"
        field="value"
        categoryField="status"
        [autoFit]="autofit"
        [colorField]="'color'"
        [labels]="{ visible: true, position: 'outsideEnd', content: labelContent }"
      >
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
  </kendo-chart>
}
