<div class="hud-container">
  <h2 class="hud-title">HUD</h2>

  <div class="metrics-container">
    <div class="tile">
      <div class="tile-title">
        <h5 class="title">Unresolved Threats</h5>
      </div>
      <div class="content">
        <h1>33</h1>
      </div>
    </div>

    <div class="tile">
      <div class="tile-title">
        <h5 class="title">Out Of Date Agents</h5>
      </div>
      <div class="content">
        <h1>8</h1>
      </div>
    </div>

    <div class="tile">
      <div class="tile-title">
        <h5 class="title">Threat Reboot Required</h5>
      </div>
      <div class="content">
        <h1>13</h1>
      </div>
    </div>

    <div class="tile">
      <div class="tile-title">
        <h5 class="title">Rogue Devices Detected</h5>
      </div>
      <div class="content">
        <h1>2</h1>
      </div>
    </div>

    <div class="tile">
      <div class="tile-title">
        <h5 class="title">Active Licenses</h5>
      </div>
      <div class="content">
        <h1>131</h1>
      </div>
    </div>
  </div>
</div>
