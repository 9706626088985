import { Component, signal } from '@angular/core';
import { ChartsModule, RenderEvent, SeriesLabelsContentArgs, SeriesVisualArgs } from '@progress/kendo-angular-charts';
import { geometry, Element, Layout, Text } from '@progress/kendo-drawing';
import { IntlService } from '@progress/kendo-angular-intl';
import 'hammerjs';
import { LoaderModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-agent-status',
  standalone: true,
  imports: [ChartsModule, LoaderModule],
  templateUrl: './agent-status.component.html',
  styleUrl: './agent-status.component.scss',
})
export class AgentStatusComponent {
  public autofit = true;
  public isLoading = signal(false);

  public chartData: any[] = [
    { status: 'Connected', value: 59, color: '#4B4ACF' },
    { status: 'Disconnecting', value: 23, color: '#3F9D91' },
    { status: 'Disconnected', value: 12, color: '#98A2B3' },
    { status: 'Connecting', value: 6, color: '#9747FF' },
  ];

  private center!: geometry.Point;
  private radius!: number;

  // Necessary to bind `this` to the AppComponent instance
  public visualHandler = this.visual.bind(this);

  constructor(private intl: IntlService) {
    this.labelContent = this.labelContent.bind(this);
  }

  public visual(e: SeriesVisualArgs): Element {
    // Obtain parameters for the segments
    if (!e.center || e.innerRadius === undefined) {
      throw new Error('Required chart parameters are missing');
    }
    this.center = e.center;
    this.radius = e.innerRadius;

    // Create default visual
    return e.createVisual();
  }

  // Need to find a way to scale the center text as the chart flexes
  public onRender(e: RenderEvent): void {
    const circleGeometry = new geometry.Circle([this.center.x, this.center.y], this.radius);
    const bbox = circleGeometry.bbox();

    const heading = new Text('318', [0, 0], {
      font: '28px Verdana,Arial,sans-serif',
    });

    const line1 = new Text('total agents', [0, 0], {
      font: '16px Verdana,Arial,sans-serif',
    });

    const layout = new Layout(bbox, {
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      spacing: 5,
    });

    layout.append(heading, line1);
    layout.reflow();

    e.sender.surface.draw(layout);
  }

  public labelContent(args: SeriesLabelsContentArgs): string {
    const percentage = args.value / this.getTotalValue();
    return `${args.category}: ${this.intl.formatNumber(percentage, 'p0')}`;
  }

  private getTotalValue(): number {
    return this.chartData.reduce((sum, item) => sum + item.value, 0);
  }
}
