<kendo-grid [data]="data()" [pageable]="true" [pageSize]="gridService.state.take" [skip]="gridService.state.skip"
  [(filter)]="gridService.state.filter" [loading]="gridService.loading"
  (dataStateChange)="gridService.onStateChange($event)" [sort]="this.gridService.state.sort" [sortable]="false"
  [filterable]="false" #grid>
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton (click)="addSource()" [svgIcon]="plusIcon" themeColor="primary">
      Add Source
    </button>
  </ng-template>
  <kendo-grid-column field="id" title="Id" [width]="300">
  </kendo-grid-column>
  <kendo-grid-column field="mapped_customer" title="Mapped Customer">
  </kendo-grid-column>
  <kendo-grid-column field="created_by" title="Created By">
  </kendo-grid-column>
  <kendo-grid-column field="created_at" title="Created">
  </kendo-grid-column>
  <kendo-grid-column field="last_sync" title="Last Sync">
  </kendo-grid-column>
  <kendo-grid-column field="active" title="Active">
    <ng-template kendoGridCellTemplate let-dataItem>
      @if(dataItem.active){
        ✅
      }@else{
        ⛔
      }
    </ng-template>
  </kendo-grid-column>
</kendo-grid>