import { Component, CreateEffectOptions, effect, inject, input, signal } from '@angular/core';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { FunnelData } from '../../../../../../../core/services/analytics.service';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { CommonModule } from '@angular/common';
import { SlaViolationsService } from '../sla-adherence/services/slaviolations.service';
import { ExcelModule, GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { MenusModule } from '@progress/kendo-angular-menu';

@Component({
  selector: 'app-ticket-closure-breakdown',
  standalone: true,
  imports: [ChartsModule, LoaderModule, CommonModule, GridModule, DialogsModule, ExcelModule, MenusModule],
  templateUrl: './ticket-closure-breakdown.component.html',
  styleUrl: './ticket-closure-breakdown.component.scss',
  providers: [SlaViolationsService],
})
export class TicketClosureBreakdownComponent extends BaseComponent {
  public gridService = inject(SlaViolationsService);
  public ticketClosureBreakdownData = input<FunnelData[]>([]);
  public chartData = signal<FunnelData[]>([]);

  public organizationId = input<number | null>();
  public dateRange = input<string[] | null>(null);

  public isLoading = signal(true);
  public showPopup = false;

  constructor() {
    super();
    this.initEffect();
  }

  initEffect() {
    effect(
      () => {
        if (this.ticketClosureBreakdownData()) {
          this.chartData.set(this.ticketClosureBreakdownData() || []);
          this.isLoading.set(false);
        } else {
          this.chartData.set([]);
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  onContextMenuSelect(event: any) {
    if (event?.text === 'View Data') {
      this.gridService.setDateRange(this.dateRange());
      this.gridService.url = `${environment.apiUrl}/v1/analytics/administrative/ticketclosurebreakdown(${this.organizationId()})`;
      this.gridService.read();
      this.showPopup = true;
    } else {
      console.warn('Invalid event structure:', event);
    }
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };

  // Close popup
  close() {
    this.showPopup = false;
  }
}
