<app-org-appbar></app-org-appbar><br />
<div class="container-fluid p-0">
  <div
    class="row"
    style="height: calc(100vh - 78px)"
  >
    <!-- Integration List Section -->
    <div class="col-4 integration-list-container">
      <div class="integration-actions-container">
        <div #integrationactions>
          <div class="row">
            <div class="integration-container pt-4">
              <div>
                <h5>Ticketing</h5>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div style="overflow-y: auto; height: calc(100vh - 245px)">
        <kendo-tilelayout-item-body class="flex-body p-0">
          @for (ingestor of ingestorIntegrations(); track ingestor.id) {
            <div
              (click)="ingestorIntegrationClick(ingestor)"
              [ngClass]="{ active: true }"
              style="margin-bottom: 5px"
            >
              <kendo-card
                class="centered-card"
                width="100%"
              >
                <img [src]="ingestor.icon" alt="{{ingestor.name}}" />
              </kendo-card>
            </div>
          }
          <div
            (click)="addIntegration('connectWise')"
            [ngClass]="{ active: isIntegrationActive('connectWise') }"
            style="margin-bottom: 5px"
          >
            <kendo-card
              class="centered-card"
              width="100%"
              [ngClass]="{
                active: selectedIntegrationType?.integrationType === 'connectWise',
                disabled: isDisabled('connectWise')
              }"
            >
              <img
                src="../../../assets/logos/connectwise.png"
                alt="stellarcyber"
              />
            </kendo-card>
          </div>

          <div
            (click)="addIntegration('serviceNow')"
            [ngClass]="{ active: isIntegrationActive('serviceNow') }"
            style="margin-bottom: 5px"
          >
            <kendo-card
              class="centered-card"
              width="100%"
              [ngClass]="{
                active: selectedIntegrationType?.integrationType === 'serviceNow',
                disabled: isDisabled('serviceNow')
              }"
              style="margin-bottom: 5px"
            >
              <img
                src="../../../assets/logos/servicenow.png"
                alt="sentinelone"
              />
            </kendo-card>
          </div>

          <div class="card-wrapper">
            <kendo-badge
              class="coming-soon-badge"
              themeColor="primary"
              >Coming Soon</kendo-badge
            >
            <kendo-card
              class="centered-card disabled-card"
              width="100%"
              style="margin-bottom: 5px"
            >
              <img
                src="../../../assets/logos/manageengine.png"
                alt="sentinelone"
              />
            </kendo-card>
          </div>

          <div class="card-wrapper">
            <kendo-badge
              class="coming-soon-badge"
              themeColor="primary"
              >Coming Soon</kendo-badge
            >
            <kendo-card
              class="centered-card disabled-card"
              width="100%"
            >
              <img
                src="../../../assets/logos/autotask.png"
                alt="sentinelone"
              />
            </kendo-card>
          </div>
        </kendo-tilelayout-item-body>
      </div>
    </div>
    <div class="col-8 integration-preview-container">
      <div style="padding: 0 20px 20px 20px; width: 100%;">
        <div class="integration-app-container">
          @if (selectedIngestorType) {
            <h4>{{selectedIngestorType.title}}</h4>
            <app-ingestor-sources 
              [organizationId]="organizationId" 
              [ingestorIntegration]="selectedIngestorType"
              (saved)="onSave()">
            </app-ingestor-sources>
            }
          @else if (selectedIntegrationType) {
            <app-servicenow
              [organizationId]="organizationId"
              [integrationData]="activeIntegration()"
              *ngIf="selectedIntegrationType.integrationType == 'serviceNow'"
              (saved)="onSave()"
            ></app-servicenow>
            <app-connectwise
              [organizationId]="organizationId"
              [integrationData]="activeIntegration()"
              *ngIf="selectedIntegrationType.integrationType == 'connectWise'"
              (saved)="onSave()"
            ></app-connectwise>
          }
        </div>
      </div>

      @if (saveConfirmDialog === true) {
        <kendo-dialog
          title="Successful"
          (close)="closeDialog('cancel')"
          [minWidth]="250"
          [width]="450"
        >
          <p style="margin: 30px; text-align: center">Connection Saved Successfully.</p>
          <kendo-dialog-actions>
            <button
              kendoButton
              themeColor="primary"
              type="button"
              (click)="closeDialog('no')"
            >
              Ok
            </button>
          </kendo-dialog-actions>
        </kendo-dialog>
      }
    </div>
  </div>
</div>
