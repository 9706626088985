import { Component, signal, ViewEncapsulation } from '@angular/core';
import { infoCircleIcon, moreVerticalIcon, sparklesIcon } from '@progress/kendo-svg-icons';
import { IconsModule, SVGIcon } from '@progress/kendo-angular-icons';
import { CyflarePeriodComponent } from '../../../../../core/components/cyflare-period/cyflare-period.component';
import { HudComponent } from './components/hud/hud.component';
import { AgentStatusComponent } from './components/agent-status/agent-status.component';
import { ThreatStatusComponent } from './components/threat-status/threat-status.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-edr',
  standalone: true,
  imports: [CyflarePeriodComponent, HudComponent, IconsModule, AgentStatusComponent, ThreatStatusComponent],
  templateUrl: './edr.component.html',
  styleUrl: './edr.component.scss',
})
export class EdrComponent {
  public selectedDateRange = signal<string[]>([]);
  public infoCircleIcon: SVGIcon = infoCircleIcon;
}
