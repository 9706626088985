import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from './odata.service';
import { environment } from '../../../environments/environment';
import { O365AccessEvent } from '../models/entities';

@Injectable()
export class O365AccountAccessService extends ODataService<O365AccessEvent> {
  private apiUrl = '';
  constructor() {
    const url = `${environment.apiUrl}/v1/analytics/administrative/`;
    super(inject(HttpClient), url);
    this.apiUrl = url;
    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'timestamp', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public getO365AccessEvents(organizationId: number | null) {
    this.url = organizationId ? this.apiUrl + `o365log(${organizationId})/` : this.apiUrl;
    return this.read();
  }

  public geto365multiplecountriesssuccesslogins(organizationId: number | null) {
    this.url = organizationId ? this.apiUrl + `o365multiplecountriesssuccessloginreport(${organizationId})/` : this.apiUrl;
    return this.read();
  }

  public geto365multiplesourcefailedlogins(organizationId: number | null) {
    this.url = organizationId ? this.apiUrl + `o365multiplesourcefailedloginreport(${organizationId})/` : this.apiUrl;
    return this.read();
  }

  public geto365singlesourcefailedlogins(organizationId: number | null) {
    this.url = organizationId ? this.apiUrl + `o365singlesourcefailedloginreport(${organizationId})/` : this.apiUrl;
    return this.read();
  }
}

export interface O365AccessEventResponse extends O365AccessEvent {}
