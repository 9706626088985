import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, Renderer2, ViewChild, ViewEncapsulation, effect, inject, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DrawerItem, DrawerSelectEvent, LayoutModule } from '@progress/kendo-angular-layout';
import { IconsModule } from '@progress/kendo-angular-icons';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { SVGIcon, bellIcon, homeIcon, userIcon, infoCircleIcon, gearsIcon, chevronDoubleLeftIcon, chevronDoubleRightIcon, chevronDownIcon, hyperlinkOpenIcon, graphIcon, documentManagerIcon, trackChangesIcon, chartPieIcon, xIcon, arrowLeftIcon, menuIcon, chartColumnStackedIcon, layoutStackedIcon } from '@progress/kendo-svg-icons';
import { CommonModule, DOCUMENT } from '@angular/common';
import { catchError, filter, of, takeUntil } from 'rxjs';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { User } from '../../../core/models/user';
import { PopupService, PopupRef } from '@progress/kendo-angular-popup';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { UserService } from '../../admin/users/services/user.service';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonceService } from './nonce.service';
import { parseNumber } from '@progress/kendo-angular-intl';
import { OrganizationService } from '../../admin/organizations/services/organization.service';
import { VirtualDropdownComponent } from '../../../core/components/virtual-dropdown/virtual-dropdown.component';
import { Organization } from '../../../core/models/entities';
import { LogoutService } from '../../../core/services/logout.service';
import { EventSourceService } from '../../../core/services/event-source.service';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { VipSupportComponent } from './components/vip-support/vip-support.component';
import { EventService } from '../../../core/services/event.service';

interface ExtendedDrawerItem extends DrawerItem {
  svgIcon?: SVGIcon;
  path?: string;
  selected?: boolean;
  external?: boolean;
  cssClass?: string;
}
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-customer-layout',
  standalone: true,
  imports: [DialogsModule, CommonModule, RouterModule, DropDownListModule, LayoutModule, ButtonsModule, IconsModule, NavigationModule, IndicatorsModule, VirtualDropdownComponent],
  templateUrl: './customer-layout.component.html',
  styleUrl: './customer-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserService, OrganizationService, NonceService],
})
export class CustomerLayoutComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('virtualDropdown', { static: false }) orgDropDown: VirtualDropdownComponent<Organization> | undefined;
  @ViewChild('anchor', { static: false }) anchor!: ElementRef;
  private user = inject(User);
  private popupService = inject(PopupService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  public orgService = inject(OrganizationService);
  public logoutService = inject(LogoutService);
  public eventSourceService = inject(EventSourceService);
  public eventService = inject(EventService);

  public opened = false;
  public toggleText = 'Show';
  public showBackButton = false;

  avatarText: string | null = null;

  private popupRef: PopupRef | undefined;
  bodyElement: ElementRef;
  public orgs = signal<{ id: number; name: string; account_type: number }[]>([]);

  public selected: ExtendedDrawerItem | undefined;
  public chevronDoubleLeftIcon: SVGIcon = chevronDoubleLeftIcon;
  public chevronDoubleRightIcon: SVGIcon = chevronDoubleRightIcon;
  public bellIcon: SVGIcon = bellIcon;
  public xIcon: SVGIcon = xIcon;
  public chartPieIcon: SVGIcon = chartPieIcon;
  public gearsIcon: SVGIcon = gearsIcon;
  public chevronDownIcon: SVGIcon = chevronDownIcon;
  public infoCircleIcon: SVGIcon = infoCircleIcon;
  public hyperlinkOpenIcon: SVGIcon = hyperlinkOpenIcon;
  public userIcon: SVGIcon = userIcon;
  public graphIcon: SVGIcon = graphIcon;
  public chartColumnStackedIcon: SVGIcon = chartColumnStackedIcon;
  public layoutStackedIcon: SVGIcon = layoutStackedIcon;
  public arrowLeftIcon: SVGIcon = arrowLeftIcon;
  public documentManagerIcon: SVGIcon = documentManagerIcon;
  public menuIcon: SVGIcon = menuIcon;
  private nonceService = inject(NonceService);
  private renderer = inject(Renderer2);
  notifications = signal<any[]>([]);
  public primaryId: number | null | undefined;
  public betaTestOrgs = [
    {
      name: 'Cyflare',
      value: 1,
    },
    {
      name: '1nteger',
      value: 6,
    },
    {
      name: 'Basswood',
      value: 1020,
    },
    {
      name: 'University of Denver',
      value: 88,
    },
    {
      name: 'Mercyhealth System',
      value: 70,
    },
    {
      name: 'Citizens and Northern Bank',
      value: 56,
    },
    {
      name: 'Palmer College of Chiropractic',
      value: 80,
    },
    {
      name: 'Simplify Inventions (5 Hour Energy)',
      value: 10,
    },
    {
      name: 'New Charter Technologies',
      value: 8,
    },
  ];

  public data: Array<any> = [];
  public expandedIndices = [1, 2];
  public isItemExpanded = (item: any): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };
  public Items: Array<ExtendedDrawerItem> = [
    {
      id: 0,
      text: 'Dashboard',
      svgIcon: homeIcon,
      path: '/customer/customer-dashboard',
      external: false,
    },
    {
      id: 1,
      text: 'Cases',
      svgIcon: trackChangesIcon,
      path: 'https://desk.cyflare.cloud/portal/',
      external: true,
    },
    {
      id: 2,
      text: 'Case Log',
      svgIcon: trackChangesIcon,
      path: '/customer/tickets-log',
      external: false,
    },
    {
      id: 3,
      text: 'Analytics',
      svgIcon: chartPieIcon,
      path: '/customer/analytics',
      external: false,
    },
    // {
    //   id: 5,
    //   text: 'SOC Tuning',
    //   svgIcon: trackChangesIcon,
    //   path: '/customer/soc-tuning',
    //   external: false,
    // },
    // {
    //   id: 6,
    //   text: 'Alert Preferences',
    //   svgIcon: bellIcon,
    //   path: '/customer/alert-preferences',
    //   external: false,
    // },
    {
      id: 7,
      text: 'Reporting',
      svgIcon: documentManagerIcon,
      path: '/customer/reporting',
      external: false,
    },
    // {
    //   id: 8,
    //   separator: true,
    // },
    // {
    //   id: 9,
    //   text: 'MSP PORTAL',
    //   svgIcon: undefined,
    //   disabled: true,
    //   cssClass: 'drawer-section-label',
    // },
    // {
    //   id: 10,
    //   text: 'Overview',
    //   svgIcon: layoutStackedIcon,
    //   path: '',
    //   external: false,
    // },
    // {
    //   id: 11,
    //   text: 'Analytics',
    //   svgIcon: chartColumnStackedIcon,
    //   path: '',
    //   external: false,
    // },
  ];

  public navigateToSettings(): void {
    let currentQueryParams = { ...this.route.snapshot.queryParams };

    if ('advisoryId' in currentQueryParams) {
      delete currentQueryParams['advisoryId'];
    }
    if ('from_email' in currentQueryParams) {
      delete currentQueryParams['from_email'];
    }
    if ('organizationId' in currentQueryParams && 'account_type' in currentQueryParams) {
      this.router.navigate(['customer/organizations/general'], { queryParams: currentQueryParams });
    } else {
      const fallbackQueryParams = { organizationId: this.user.primary_organization?.id, account_type: this.user.primary_organization?.account_type };
      this.router.navigate(['customer/organizations/general'], { queryParams: fallbackQueryParams });
    }
  }

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();
    this.initEffect();
    this.setOrganizationServiceFitler();
    this.setUserAvatarText();
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        filter((event: NavigationEnd) => !event.urlAfterRedirects.includes('auth')),
      )
      .subscribe((event: any) => {
        this.updateSelectedItem(event.urlAfterRedirects);
      });
    this.bodyElement = new ElementRef(this.document.body);
  }

  initEffect() {
    effect(() => {
      this.eventSourceService.connectToServerSentEvents(['message']).subscribe({
        next: (response: any) => {
          const data = JSON.parse(response.data);
          if (data.id) {
            this.notifications.update((x) => [...x, data]);
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
      this.primaryId = this.user.primary_organization?.id;
    });
  }

  public isBetaTestOrg(): boolean {
    return this.betaTestOrgs.some((org) => org.value === this.primaryId);
  }

  ngAfterViewInit() {
    this.getOrganization();
    this.updateDropdownOptions();
  }

  public close(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

  private setUserAvatarText() {
    const user = this.user;
    const firstName = user.first_name || '';
    const lastName = user.last_name || '';

    if (firstName && lastName) {
      this.avatarText = `${firstName.charAt(0)}${lastName.charAt(0)}`;
    } else {
      this.avatarText = null;
    }
  }

  public handlePopup(component: 'notifications' | 'vipSupport'): void {
    this.togglePopup(component);
  }

  private setOrganizationServiceFitler() {
    this.route.queryParams
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((params) => params['organizationId']),
      )
      .subscribe((params) => {
        if (!this.user.groups.includes('admin') && !this.orgService.customFilter) {
          this.orgService.customFilter = `userprofile/user/pk in (${this.user.id},)`;
        }
      });
  }

  public togglePopup(componentType: 'notifications' | 'vipSupport' = 'notifications'): void {
    if (this.popupRef) {
      // Close any open popup
      this.popupRef.close();
      this.popupRef = undefined;
    } else {
      // Dynamically decide which component to use
      const componentToOpen = componentType === 'notifications' ? NotificationsComponent : VipSupportComponent;

      this.popupRef = this.popupService.open({
        anchor: this.bodyElement,
        content: componentToOpen,
        animate: { type: 'slide', direction: 'left', duration: 200 },
        anchorAlign: { horizontal: 'right', vertical: 'top' },
        popupAlign: { horizontal: 'right', vertical: 'top' },
      });

      if (componentType === 'notifications') {
        const notificationsComponent = this.popupRef.content.instance as NotificationsComponent;

        // Set initial notification data
        notificationsComponent.notifications.set(this.notifications());

        notificationsComponent.close.subscribe(() => {
          // Update notifications data and close the popup
          this.notifications.set(notificationsComponent.notifications());
          this.togglePopup();
        });
      } else if (componentType === 'vipSupport') {
        const vipSupportComponent = this.popupRef.content.instance as VipSupportComponent;

        // Example: Add any additional setup for the VIP support component here
        vipSupportComponent.close.subscribe(() => {
          this.togglePopup();
        });
      }
    }
  }

  public onUserProfileClick(event: any): void {
    if (event.text === 'Logout') {
      this.logoutService
        .globalSignOut(this.user.id)
        .pipe(
          takeUntil(this.destroyed$),
          catchError((error) => {
            console.error('Error during logout', error);
            return of(null);
          }),
        )
        .subscribe(() => {
          this.user.clearUserData();
          this.router.navigate(['/auth/login']);
        });
    } else if (event.text === 'My Profile') {
      // Handle "My Profile" click
    } else if (event.text === 'Admin Console') {
      // Navigate to admin dashboard
      this.router.navigate(['/admin/dashboard']);
    }
  }

  public infoNav: Array<{ text: string; url: string; external: boolean }> = [
    {
      text: 'SOC Change Log',
      url: 'https://cyflare.com/soc-change-log/',
      external: true,
    },
    {
      text: 'Detections Board',
      url: '/customer/detections-board',
      external: false,
    },
    {
      text: 'Satisfaction Survey',
      url: 'https://survey.zohopublic.com/zs/QRRLP1',
      external: true,
    },
  ];

  onItemClick(e: any): void {
    const currentQueryParams = this.route.snapshot.queryParams;
    const selectedItem = e.item;
    if (e.external) {
      window.open(e.url, '_blank');
    } else {
      this.router.navigate([e.url], { queryParams: currentQueryParams });
    }
  }

  private getOrganization() {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        filter((event: NavigationEnd) => !event.urlAfterRedirects.includes('auth')),
      )
      .subscribe((event: any) => {
        this.updateSelectedItem(event.urlAfterRedirects);
      });

    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      if ('organizationId' in params && params['organizationId'].trim() !== '') {
        const orgId = parseNumber(params['organizationId']);
        this.orgService.getOrganizationById(orgId).subscribe((org) => {
          const defaultOrg = {
            id: org.id,
            name: org.name,
            account_type: org.account_type,
          };
          this.orgDropDown?.value.set(defaultOrg as Organization);
        });
      } else {
        this.orgDropDown?.value.set({ id: null, name: 'Please select an Org', account_type: null } as Organization);
      }
    });
  }

  private updateDropdownOptions(): void {
    if (this.user.groups.includes('admin')) {
      this.data = [
        { text: 'Admin Console', icon: 'settings' },
        // { text: 'My Profile', icon: 'user' },
        { text: 'Logout', icon: 'log-out' },
      ];
    } else {
      this.data = [
        // { text: 'My Profile', icon: 'user' },
        { text: 'Logout', icon: 'log-out' },
      ];
    }
  }

  private updateSelectedItem(url: string): void {
    const path = url.split('?')[0]; // Strip out query parameters
    const index = this.Items.findIndex((item) => item.path === path);

    if (index > -1) {
      this.Items = this.Items.map((item, i) => {
        item.selected = false;
        if (i === index) {
          return { ...item, selected: true };
        } else {
          return item;
        }
      });
    }
  }

  onOrgDropdownChanged($event: { id: number; name: string; account_type: string }) {
    console.log('Org dropdown change:', $event);
    this.router.navigate([], {
      relativeTo: this.router.routerState.root,
      queryParams: { organizationId: $event.id },
      queryParamsHandling: 'merge',
    });

    this.eventService.orgChange($event as any);
  }

  public onSelect(ev: DrawerSelectEvent): void {
    const item = ev.item as ExtendedDrawerItem;
    const current = ev.item.id;
    let currentQueryParams = { ...this.route.snapshot.queryParams };

    if (this.expandedIndices.indexOf(current) >= 0) {
      this.expandedIndices = this.expandedIndices.filter((id) => id !== current);
    } else {
      this.expandedIndices.push(current);
    }

    if ('advisoryId' in currentQueryParams) {
      delete currentQueryParams['advisoryId'];
    }
    if ('from_email' in currentQueryParams) {
      delete currentQueryParams['from_email'];
    }

    if (item.external) {
      window.open(item.path, '_blank');
    } else if (item.path) {
      if ('organizationId' in currentQueryParams) {
        this.router.navigate([item.path], { queryParams: currentQueryParams });
      } else {
        const fallbackQueryParams = { organizationId: this.user.primary_organization?.id, account_type: this.user.primary_organization?.account_type };
        this.router.navigate([item.path], { queryParams: fallbackQueryParams });
      }
    }

    this.Items.forEach((item) => {
      if (item.text) {
        item.selected = false;
      }
    });
    const selectedItem = ev.item;
    selectedItem.selected = true;
    this.selected = selectedItem.text;
  }

  ngOnInit(): void {
    this.addZohoScript();
  }

  addZohoScript(): void {
    const nonce = this.nonceService.generateNonce();
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://desk.zoho.com/portal/api/web/inapp/224546000107746001?orgId=654924228';
    script.defer = true;
    script.nonce = nonce;
    console.log('Nonce:', nonce);
    document.body.appendChild(script);
  }
}
