import { CommonModule } from '@angular/common';
import { Component, effect, inject, Input, signal, ViewChild } from '@angular/core';
import { ContextService, ExcelModule, GridComponent, GridDataResult, GridModule, PDFModule, PDFService } from '@progress/kendo-angular-grid';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { BaseComponent } from '../../../../../../core/shared/common/base.component';
import { SocEventsService } from '../../../../../../core/services/soc-events.service';
import { ActivatedRoute } from '@angular/router';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-soc-events',
  standalone: true,
  imports: [GridModule, CommonModule, LoaderModule, PDFModule, ExcelModule],
  templateUrl: './soc-events.component.html',
  styleUrl: './soc-events.component.scss',
  providers: [SocEventsService, PDFService, ContextService],
})
export class SocEventsComponent extends BaseComponent {
  @ViewChild('grid', { static: false }) grid!: GridComponent;

  private sanitizer = inject(DomSanitizer);
  headerHtml!: SafeHtml;
  @Input() set htmlContent(value: string) {
    this.headerHtml = this.sanitizer.bypassSecurityTrustHtml(value);
  }
  public landscape = false;
  public fileName: string = '';

  public gridService = inject(SocEventsService);
  private route = inject(ActivatedRoute);
  public organizationId: number | null = null;

  public selectedDateRange = signal<string[]>([]);
  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      this.route.queryParams.subscribe((params) => {
        this.organizationId = params['organizationId'];
      });
      this.applyFilter();
    });
  }

  public exportPDF(fileName: string) {
    this.fileName = `${fileName}.pdf`;
    this.grid.saveAsPDF();
  }

  public exportExcel(fileName: string) {
    this.fileName = `${fileName}.xlsx`;
    this.grid.saveAsExcel();
  }

  applyFilter() {
    const dateRange = this.selectedDateRange();
    if (!dateRange) return;
    this.filter = {
      logic: 'and',
      filters: [],
    };
    const field = 'alert_received_on';
    this.filter.filters.push({
      field: field,
      operator: 'gt',
      value: dateRange[0],
    });

    this.filter.filters.push({
      field: field,
      operator: 'lt',
      value: dateRange[1], //
    });

    // Update the grid filter state
    this.gridService.state.filter = {
      logic: 'and',
      filters: this.filter.filters,
    };

    this.gridService.getSocEvents(this.organizationId);
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };
}
