import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private http = inject(HttpClient);
  private apiUrl = environment.apiUrl;

  getReports(organizationId: number | null): Observable<any[]> {
    if (!organizationId) {
      return new Observable(); // or return empty array: of([])
    }
    return this.http.get<any[]>(`${this.apiUrl}/v1/reporting/templates/?organization_id=${organizationId}`).pipe(map((reports) => reports.sort((a, b) => a.id - b.id)));
  }
}
