<div class="subscription-switches">
  <div class="switch-container">
    <kendo-label
      class="switch-label"
      text="XDR (Extended Detection and Response)"
    >
      <kendo-switch
        [checked]="false"
        (valueChange)="onXdrChange($event)"
      ></kendo-switch>
    </kendo-label>
  </div>

  <div class="switch-container">
    <kendo-label
      class="switch-label"
      text="EDR (Endpoint Detection and Response)"
    >
      <kendo-switch
        [checked]="false"
        (valueChange)="onEdrChange($event)"
      ></kendo-switch>
    </kendo-label>
  </div>

  <div class="switch-container">
    <kendo-label
      class="switch-label"
      text="VSS (Vulnerability Scanning Service)"
    >
      <kendo-switch
        [checked]="false"
        (valueChange)="onVssChange($event)"
      ></kendo-switch>
    </kendo-label>
  </div>

  <div class="switch-container">
    <kendo-label
      class="switch-label"
      text="ES (Email Security)"
    >
      <kendo-switch
        [checked]="false"
        (valueChange)="onEsChange($event)"
      ></kendo-switch>
    </kendo-label>
  </div>

  <div class="switch-container">
    <kendo-label
      class="switch-label"
      text="Network Discovery"
    >
      <kendo-switch
        [checked]="false"
        (valueChange)="onNetworkDiscoveryChange($event)"
      ></kendo-switch>
    </kendo-label>
  </div>

  <div class="switch-container">
    <kendo-label
      class="switch-label"
      text="Extended Log Retention"
    >
      <kendo-switch
        [checked]="false"
        (valueChange)="onLogRetentionChange($event)"
      ></kendo-switch>
    </kendo-label>
  </div>

  <div class="switch-container">
    <kendo-label
      class="switch-label"
      text="Darkweb Monitoring"
    >
      <kendo-switch
        [checked]="false"
        (valueChange)="onDarkwebChange($event)"
      ></kendo-switch>
    </kendo-label>
  </div>

  <div class="switch-container">
    <kendo-label
      class="switch-label"
      text="Financial Risk Analysis"
    >
      <kendo-switch
        [checked]="false"
        (valueChange)="onFinancialRiskChange($event)"
      ></kendo-switch>
    </kendo-label>
  </div>

  <div class="switch-container">
    <kendo-label
      class="switch-label"
      text="Web Filtering"
    >
      <kendo-switch
        [checked]="false"
        (valueChange)="onWebFilteringChange($event)"
      ></kendo-switch>
    </kendo-label>
  </div>

  <div class="switch-container">
    <kendo-label
      class="switch-label"
      text="SSPM (SaaS Security Posture Management)"
    >
      <kendo-switch
        [checked]="false"
        (valueChange)="onSspmChange($event)"
      ></kendo-switch>
    </kendo-label>
  </div>
</div>

<style>
  .subscription-switches {
    padding: 20px;
  }

  .switch-container {
    margin-bottom: 20px;
  }

  .switch-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    min-width: 300px;
  }
</style>
