<div class="date-filter"></div>
<kendo-tabstrip>
  <kendo-tabstrip-tab
    title="SOC"
    [selected]="true"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <app-soc></app-soc>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <!-- <kendo-tabstrip-tab
    title="EDR"
    [selected]="false"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <app-edr></app-edr>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab
    title="VSS"
    [selected]="false"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <app-vss></app-vss>
      </div>
    </ng-template>
  </kendo-tabstrip-tab> -->
  <kendo-tabstrip-tab
    title="MITRE ATT&CK"
    [selected]="false"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <app-mitreattack></app-mitreattack>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
