import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation, OnInit, OnChanges, SimpleChanges, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { SchedulerService } from './services/scheduler.service';
import { LoaderModule } from '@progress/kendo-angular-indicators';

// yo dawg, these are our form controls 🎮
export interface SchedulerForm {
  id: FormControl<number | null>;
  monthlyEmail: FormControl<string | null>;
  weeklyEmail: FormControl<string | null>;
  monthlySwitch: FormControl<boolean | null>;
  weeklySwitch: FormControl<boolean | null>;
}

// what we get back from the api 📦
export interface ScheduleResponse {
  id: number;
  organization: number;
  organization_name: string;
  report_template: number;
  template_name: string;
  report_subject: string;
  report_period: number;
  period_type: string;
  period_type_display: string;
  report_schedule: number;
  schedule_type: string;
  schedule_type_display: string;
  report_recipients: string;
}

// what we send to the api 🚀
export interface SchedulerPayload {
  report_identifier: string;
  monthly_email: string | null;
  weekly_email: string | null;
  monthly_enabled: boolean;
  weekly_enabled: boolean;
  is_default: boolean;
  organization_id: number;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-scheduler',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, LabelModule, InputsModule, DateInputsModule, ButtonsModule, DropDownsModule, DialogsModule, CommonModule, LoaderModule],
  templateUrl: './scheduler.component.html',
  styleUrl: './scheduler.component.scss',
})
export class SchedulerComponent implements OnInit, OnChanges {
  public xIcon: SVGIcon = xIcon;
  public monthlySwitch = false;
  public weeklySwitch = false;
  @Input() reportTitle!: string;
  @Input() reportIdentifier!: string;
  @Input() isDefault?: boolean;
  @Input() organizationId!: number;
  @Output() close = new EventEmitter<void>();
  public isLoading$ = false;

  // Add this property to track validation error
  public validationError: string | null = null;

  // Form group for the scheduler form
  schedulerForm: FormGroup<SchedulerForm> = new FormGroup<SchedulerForm>({
    id: new FormControl<number | null>(null),
    monthlyEmail: new FormControl<string | null>(null),
    weeklyEmail: new FormControl<string | null>(null),
    monthlySwitch: new FormControl<boolean>(false), // Initialize with false
    weeklySwitch: new FormControl<boolean>(false), // Initialize with false
  });

  // Add service injection
  private schedulerService = inject(SchedulerService);

  // Add property to store schedules
  public schedules: ScheduleResponse[] = [];
  public isLoading = true;

  constructor() {}

  ngOnInit(): void {
    // Initialize component if needed
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.reportIdentifier && this.organizationId) {
      this.loadSchedules();
    }
  }

  public loadSchedules(): void {
    // bail if we don't have the goods 🛑
    if (!this.reportIdentifier || !this.organizationId) {
      return;
    }

    this.isLoading = true;
    // yeet that request to the api 🎯
    this.schedulerService.getSchedules(this.organizationId, this.reportIdentifier).subscribe({
      next: (response) => {
        this.schedules = response;
        this.updateFormWithExistingSchedule();
        this.isLoading = false;
      },
      error: (error) => {
        // rip in peace 💀
        this.validationError = 'Failed to load schedule information';
        this.isLoading = false;
      },
    });
  }

  private updateFormWithExistingSchedule(): void {
    // init our schedule flags and emails 🏁
    let monthlyEmail = null;
    let weeklyEmail = null;
    let monthlyEnabled = false;
    let weeklyEnabled = false;

    // process them schedules fam 🔄
    this.schedules.forEach((schedule) => {
      if (schedule.schedule_type === 'MONTHLY_FIRST') {
        monthlyEnabled = true;
        monthlyEmail = schedule.report_recipients;
      }

      if (schedule.schedule_type === 'WEEKLY_MONDAY') {
        weeklyEnabled = true;
        weeklyEmail = schedule.report_recipients;
      }
    });

    // yeet those values into the form 🎯
    this.schedulerForm.patchValue({
      monthlyEmail: monthlyEmail,
      weeklyEmail: weeklyEmail,
      monthlySwitch: monthlyEnabled,
      weeklySwitch: weeklyEnabled,
    });

    // update dem switches too 🔌
    this.monthlySwitch = monthlyEnabled;
    this.weeklySwitch = weeklyEnabled;
  }

  public save($event: Event) {
    // make sure we got valid data before sending 🔍
    if (!this.isValidSchedule()) {
      this.validationError = 'At least one email schedule must be enabled with an email address';
      return;
    }

    const formData = this.prepareFormDataForSave();

    // send it! 🚀
    this.validationError = null;
    this.schedulerService.saveSchedule(formData).subscribe({
      next: () => {
        this.close.emit();
      },
      error: () => {
        // big oof 💀
        this.validationError = 'Failed to save schedule. Please try again.';
      },
    });
  }

  private prepareFormDataForSave(): SchedulerPayload {
    // grab them emails if switches are on 📧
    const monthlyEmail = this.monthlySwitch ? this.schedulerForm.get('monthlyEmail')?.value ?? null : null;
    const weeklyEmail = this.weeklySwitch ? this.schedulerForm.get('weeklyEmail')?.value ?? null : null;

    // pack it up, pack it in 📦
    return {
      report_identifier: this.reportIdentifier,
      monthly_email: monthlyEmail,
      weekly_email: weeklyEmail,
      monthly_enabled: this.monthlySwitch,
      weekly_enabled: this.weeklySwitch,
      is_default: this.isDefault ?? false,
      organization_id: this.organizationId,
    };
  }

  private isValidSchedule(): boolean {
    // check if our schedules are legit 🎯
    const monthlyEnabled = this.schedulerForm.get('monthlySwitch')?.value ?? false;
    const weeklyEnabled = this.schedulerForm.get('weeklySwitch')?.value ?? false;
    const monthlyEmail = this.schedulerForm.get('monthlyEmail')?.value ?? '';
    const weeklyEmail = this.schedulerForm.get('weeklyEmail')?.value ?? '';

    // no email with enabled switch? that's a no from me dawg 🙅‍♂️
    if (monthlyEnabled && !monthlyEmail) {
      return false;
    }

    if (weeklyEnabled && !weeklyEmail) {
      return false;
    }

    // at least one schedule needs to be valid 👌
    return (monthlyEnabled && Boolean(monthlyEmail)) || (weeklyEnabled && Boolean(weeklyEmail));
  }

  public cancel() {
    this.close.emit();
  }

  public monthlySchedule(value: boolean): void {
    this.monthlySwitch = value;
  }

  public weeklySchedule(value: boolean): void {
    this.weeklySwitch = value;
  }
}
